import { StrictMode, startTransition, useEffect } from 'react'

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches
    })
  ],
  release: window.ENV.COMMIT_SHA,
  tracesSampleRate: 0.0
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
